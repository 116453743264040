import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import PaypalController from "./paypal_controller";
application.register("paypal", PaypalController);

export { application }
