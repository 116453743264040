import { Controller } from "@hotwired/stimulus"

// import $ from "jquery"

// Connects to data-controller="scroll-to"
export default class extends Controller {
    connect() {
        window.paypal
            .Buttons({
                async createOrder() {

                    let locale = document.getElementById("paypal-button-container").getAttribute('data-locale');
                    let order = document.getElementById("paypal-button-container").getAttribute('data-order')


                    try {
                        const response = await fetch("/api/orders", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            // use the "body" param to optionally pass additional order information
                            // like product ids and quantities
                            body: JSON.stringify({
                                locale: locale,
                                order: order,
                                cart: [
                                    {
                                        id: "Business Coaching Workbook",
                                        quantity: 1,
                                    },
                                ],
                            }),
                        });

                        const orderData = await response.json();

                        if (orderData.id) {
                            return orderData.id;
                        } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail
                                ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                        }
                    } catch (error) {
                        console.error(error);
                        showMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
                    }
                },
                async onApprove(data, actions) {
                    try {
                        const response = await fetch(`/api/orders/${data.orderID}/capture`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });

                        const orderData = await response.json();
                        // Cases to handle:
                        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                        //   (2) Other non-recoverable errors -> Show a failure message

                        const errorDetail = orderData?.details?.[0];

                        if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                            // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                            return actions.restart();
                        } else if (errorDetail) {
                            // (2) Other non-recoverable errors -> Show a failure message
                            throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                        } else if (!orderData.purchase_units) {
                            throw new Error(JSON.stringify(orderData));
                        } else {

                            const transaction =
                                orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                                orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

                            let idWait = document.querySelector("#wait");
                            idWait.classList.add('d-block');
                            idWait.classList.remove('d-none');

                            document.querySelector('#payment_info').classList.add('d-none');
                            document.querySelector('#paypal-button-container').classList.add('d-none');

                            let order = document.getElementById("paypal-button-container").getAttribute('data-order')
                            let token = document.getElementById("paypal-button-container").getAttribute('data-order-token')

                            const after_payment = await fetch('/shop/'+token+'/paid', {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    order: order,
                                    transaction: transaction.id
                                }),
                            });

                            const afterPaymentData = await after_payment.json();

                            if (afterPaymentData.msg === 'ok') {
                                let idThanks = document.querySelector("#thank_you");
                                idThanks.classList.add('d-block');
                                idThanks.classList.remove('d-none');

                                idWait.classList.add('d-none');
                                idWait.classList.remove('d-block');
                            }

                        }
                    } catch (error) {
                        console.error(error);
                        showMessage(
                            `Sorry, your transaction could not be processed...<br><br>${error}`,
                        );
                    }
                },
            })
            .render("#paypal-button-container");

        function showMessage(message) {
            const container = this.element.querySelector("#result-message");
            if (container) {
                container.innerHTML = message;
            }
        }

    }



}