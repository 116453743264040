import { Controller } from "@hotwired/stimulus"

//
//== FANCYBOX
import { Fancybox } from "@fancyapps/ui";

export default class extends Controller {
  connect () {
    Fancybox.bind("[data-fancybox]", {
      Toolbar: {
        display: {
          left: [],
          middle: [],
          right: ["zoom", "slideshow", "fullscreen", "thumbs", "close"],
        },
      },
      on: {
        init: (fancybox) => {
          history.pushState({ fancybox: true }, "", window.location.href);
        }
      }
    })
  }

  disconnect () {
    Fancybox.destroy();
  }
}
