// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

window.addEventListener('popstate', function(event) {
  if (!event.state || !("fancybox" in event.state)) {
    window.location.replace(window.location.href);
  }
});

